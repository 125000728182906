import Image from "next/image";
import styles from "./studio.module.scss";
import studioFg from "/public/images/artist_foreground.png";
import studioBg from "/public/images/artists_bg.jpg";

export default function Studio({}) {
  return (
    <div className={styles.studioWrapper}>
      <div className={styles.fg}>
        <div className={styles.studioFg}>
          <Image
            src={studioFg}
            layout="fill"
            objectFit="contain"
            alt="Foreground Image"
          />
        </div>
      </div>

      <div className={styles.bg}>
        <Image src={studioBg} layout="fill" alt="Background Image" priority />
      </div>
    </div>
  );
}
