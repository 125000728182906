import Image from "next/image";
import styles from "./restaurant.module.scss";
import RestaurantFg from "/public/images/lamp.png";
import RestaurantBg from "/public/images/business_bg.jpg";

export default function Restaurant({}) {
  return (
    <div className={styles.restaurantWrapper}>
      <div className={styles.fg}>
        <div className={styles.restaurantFg1}>
          <Image
            src={RestaurantFg}
            layout="fill"
            objectFit="contain"
            alt="Foreground Image"
          />
        </div>
        <div className={styles.restaurantFg2}>
          <Image
            src={RestaurantFg}
            layout="fill"
            objectFit="contain"
            alt="Foreground Image"
          />
        </div>
      </div>

      <div className={styles.bg}>
        <Image
          src={RestaurantBg}
          layout="fill"
          alt="Background Image"
          priority
        />
      </div>
    </div>
  );
}
