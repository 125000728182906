import Image from "next/image";
import styles from "./retail.module.scss";
import retailFg from "/public/images/Boutique_TOP.png";
import retailBg from "/public/images/retail_bg.jpg";

export default function Retail({}) {
  return (
    <div className={styles.retailWrapper}>
      <div className={styles.fg}>
        <div className={styles.retailFg}>
          <Image
            src={retailFg}
            layout="fill"
            objectFit="contain"
            alt="Foreground Image"
          />
        </div>
      </div>

      <div className={styles.bg}>
        <Image src={retailBg} layout="fill" alt="Background Image" priority />
      </div>
    </div>
  );
}
