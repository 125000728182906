import styles from "./newsCard.module.scss";
import SanityImage from "../utilities/sanityImage";
import Link from "next/link";
import { PortableText } from "@portabletext/react";
import myPortableTextComponents from "../utilities/portableTextStyle";

export default function Card({ data }) {

    const postDate = new Date(data.releaseDate);
    const dateStr = postDate.toLocaleDateString("en-us", {
        timeZone: "UTC",
        month: "long",
        day: "numeric",
        year: "numeric",
    });

    let catBGColor = "#33c486";
    if (data.category) {
        catBGColor = data.category.color;
    }

    let catStyle = {
        "backgroundColor": catBGColor,
    }

    return (
        <div className={styles.card}>
            <Link href={`/news/${data.slug.current}`}>
                <a>
                    <div className={styles.media}>
                        {data.main_image &&
                            <SanityImage layout="fill" image={data.main_image} alt={data.title} />
                        }
                    </div>
                </a>
            </Link>
            <div className={styles.info}>
                {data.category &&
                    <span style={catStyle} className={styles.category}>{data.category.title}</span>
                }
                <h2>{data.title}</h2>
                <h3>{dateStr}</h3>
                <PortableText
                    value={data.summary}
                    components={myPortableTextComponents}
                />
                <p><Link href={`/news/${data.slug.current}`}><a>read more</a></Link></p>
            </div>
            
        </div>
    )
}