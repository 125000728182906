import styles from './video_bg.module.scss';
import { getFile } from "@sanity/asset-utils";
import sanityProject from "../utilities/sanityProject";

export default function videoBG({video_file}) {

    let videoFile = null, videoSrc;
    if (video_file) {
        videoFile = getFile(video_file, sanityProject);
        videoSrc = videoFile.asset.url;
    }

    return (
        <div id="pageVideoBG" className={styles.videowrap}>
            {video_file ?
                <video className={styles.bg} autoPlay muted playsInline loop>
                    <source src={videoSrc} />
                </video>
            :
                <div className={styles.black}>
                    <video className={styles.bg} autoPlay muted playsInline loop>
                        <source src="" />
                    </video>
                </div>
            }

        </div>
    )
}