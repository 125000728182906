import styles from "./carousel.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {PortableText} from '@portabletext/react';
import myPortableTextComponents from "../utilities/portableTextStyle";
import SanityImage from '../../components/utilities/sanityImage'
import Link from "next/link";

export default function Carousel({ content }) {

  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 2000,
          speed: 1000,
          autoplay: true,
          fade: true,
        }
      }
    ]
  };
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          right: "-170px",
          alignItems: "center",
        }}
        onClick={onClick}
      >
        <div className={styles.rightArrow}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45.464"
            height="43.384"
            viewBox="0 0 45.464 43.384"
          >
            <path
              id="Path_76"
              data-name="Path 76"
              d="M21.568,0,0,21.692,21.568,43.384h23.9L23.9,21.692,45.464,0Z"
              transform="translate(-0.001 0)"
              fill="#fff"
            />
          </svg>
        </div>
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          left: "-170px",
          alignItems: "center",
        }}
        onClick={onClick}
      >
        <div className={styles.leftArrow}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45.464"
            height="43.384"
            viewBox="0 0 45.464 43.384"
          >
            <path
              id="Path_76"
              data-name="Path 76"
              d="M21.568,0,0,21.692,21.568,43.384h23.9L23.9,21.692,45.464,0Z"
              transform="translate(-0.001 0)"
              fill="#fff"
            />
          </svg>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.carouselWrapper}>
      <div className={styles.header}>
          {content.text &&
            <PortableText
              value={content.text}
              components={myPortableTextComponents}
            />
        }
      </div>
      <div className={styles.carousel}>
        <div className={styles.items}>
          <Slider {...settings}>

            {content.images.map(item =>
              <div key={item._key} className={styles.item}>
                <Link href="/channels">
                  <a>
                    <SanityImage image={item} layout="contain" alt="Loop" />
                  </a>                  
                </Link>                
              </div>
            )}
          </Slider>
        </div>
      </div>
    </div>
  );
}
