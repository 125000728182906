import styles from "./textCarousel.module.scss";
import Slider from "react-slick";
import SanityImage from "../utilities/sanityImage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TextCarousel({ content }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className={styles.carouselWrapper}>
      <div className={styles.carousel}>
        <div className={styles.items}>
          <Slider {...settings}>
            {content.testimonials.map(item =>
              <div key={item._id} className={styles.item}>
                <div className={styles.image}>
                  <SanityImage image={item.image} layout="contain" alt={item.quote} />
                </div>
                <div className={styles.header}>
                  <h1>{item.quote}</h1>
                </div>
                <div className={styles.subText}>
                  <p>{item.credit}</p>
                </div>
              </div>
            )}
          </Slider>
        </div>
      </div>
    </div>
  );
}
