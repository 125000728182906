import styles from "./news.module.scss";
import Card from "./newsCard";
import Button from "../button/button.js";
import Link from "next/link";

export default function NewsGrid({ data }) {
  return (
    <div className={styles.newsGrid}>
      <div className={styles.grid}>
        {data.map((item) => (
          <div key={item._id} className={styles.item}>
            <Card data={item} />
          </div>
        ))}
      </div>
      <div className={styles.buttonwrap}>
        <Link href="/news">
          <a>
            <Button label="more news" />
          </a>
        </Link>
      </div>
    </div>
  );
}
