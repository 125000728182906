import styles from './gradientBackground.module.scss';

export default function GradientBG({children, padding}) {

    let blockClass = styles.gradientBG;
    if (padding === "no") {
        blockClass += " " + styles.no_pad;
    }

    return (
        <div className={blockClass}>
            {children}
            <div className={styles.circle1}></div>
            <div className={styles.circle2}></div>
            <div className={styles.circle3}></div>
            <div className={styles.bg}></div>
        </div>
    )
}