import Image from "next/image";
import styles from "./home.module.scss";
import homeFg from "/public/images/AT-HOME-foreground.png";
import homeBg from "/public/images/athome_bg.jpg";

export default function Home({}) {
  return (
    <div className={styles.homeWrapper}>
      <div className={styles.fg}>
        <div className={styles.homeFg}>
          <Image
            src={homeFg}
            layout="fill"
            objectFit="contain"
            alt="Foreground Image"
          />
        </div>
      </div>

      <div className={styles.bg}>
        <Image src={homeBg} layout="fill" alt="Background Image" priority />
      </div>
    </div>
  );
}
